import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { LANDING_ROUTES } from '../routing';
import { DisclosuresUniversalService } from 'services';

import { Store } from '@ngrx/store';
import { EnrollmentState, GetElectronicDisclosureSuccessAction, CheckGuidValidAction, getBrokerInformation } from 'store';
import { getElectronicDisclosure } from 'store/selectors/disclosure.selectors';

import { map, switchMap, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { GET_CACHE, SET_CACHE } from 'app/core/utils';

@Injectable({
  providedIn: 'root'
})
export class EAgreementGuard implements CanActivate {
  constructor(private router: Router,
    private store$: Store<EnrollmentState>,
    private disclosureService: DisclosuresUniversalService) {}
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const token = route.params.guid || GET_CACHE('guid');
    if (token)
    {
      // Save guid into localstorage to prevent lose it when refresh the browser
      SET_CACHE(token, 'guid');
      this.store$.dispatch(new CheckGuidValidAction(token));
    }
    return this.store$.select(getElectronicDisclosure).pipe(
      take(1),
      switchMap(disclosure => {
        const urlAgreement = this.router.parseUrl(LANDING_ROUTES.E_AGREEMENT.path);
        if (disclosure && disclosure.accepted) {
          return of(true);
        } else {
          return this.disclosureService.getApplicationApplicantDisclosure('AxosInvestElectronicAgreement').pipe(
            map(appDisclosure => {
              if (appDisclosure) {
                this.store$.dispatch(new GetElectronicDisclosureSuccessAction(appDisclosure));
              }
              if(token) {
                return true;
              }
              return appDisclosure && appDisclosure.accepted ? true : urlAgreement;
            })
          );
        }
      }),
      take(1)
    );
  }
}
