import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PROXY_ROUTES } from './constants/routes';
import * as components from '../components';
// Feature Toogle
import {
  NgxFeatureToggleCanActivateChildGuard,
  NgxFeatureToggleCanActivateGuard
} from 'ngx-feature-toggle';
import { set as setFeatureToggle } from 'feature-toggle-service';
import { FeatureFlagService } from 'services';
import { StatesResolver, RelationShipsResolver, ApplicantResolver, ApplicantsResolver, ApplicationResolver, CountryResolver } from 'app/core/resolvers';
import { ProxyAccessGuard } from './guards';
import { ProductsResolver } from './resolvers/products.resolver';

setFeatureToggle({
  proxy: true,
  landing: true,
  enableTrustVestingType: false
});


const routes: Routes = [
  {
    path: '',
    canActivate: [
      NgxFeatureToggleCanActivateGuard,
      ProxyAccessGuard
    ],
    data: {
      featureToggle: ['proxy'],
    },
    children: [
      {
        path: PROXY_ROUTES.SEARCH_USER.path,
        component: components.ProxySearchUserComponent,
        data: {
          routeName: PROXY_ROUTES.SEARCH_USER.name,
          pageTitle: 'Search Customer | Proxy',
          banner: {
            title: 'Select an Existing Customer',
            showIcon: false,
            showProducts: false,
            subBanner: { show: false }
          }
        }
      },
      {
        path: PROXY_ROUTES.PRODUCT_SELECTION.path,
        component: components.ProductSelectionComponent,
        resolve: {
          products: ProductsResolver,
        },
        data: {
          routeName: PROXY_ROUTES.PRODUCT_SELECTION.name,
          pageTitle: 'Product Selection | Proxy',
          banner: {
            title: 'Select a Product',
            showIcon: false,
            showProducts: false,
            subBanner: { show: false }
          }
        }
      },
      {
        path: PROXY_ROUTES.CONTACT_INFO.path,
        component: components.ContactPageComponent,
        resolve: {
          states: StatesResolver,
          relationshipsCatalog: RelationShipsResolver,
          ApplicantResolver,
          ApplicantsResolver,
        },
        data: {
          routeName: PROXY_ROUTES.CONTACT_INFO.name,
          pageTitle: 'Contact Information | Proxy',
          banner: {
            title: 'Trusted Contact Information',
            showIcon: false,
            showProducts: false,
            subBanner: { show: false }
          }
        }
      },
      {
        path: PROXY_ROUTES.HANDOFF_SUCCESS.path,
        component: components.HandoffSuccessComponent,
        data: {
          routeName: PROXY_ROUTES.HANDOFF_SUCCESS.name,
          pageTitle: 'Handoff Success | Proxy',
          banner: {
            title: 'Handoff Success',
            showIcon: false,
            showProducts: false,
            subBanner: { show: false }
          }
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    StatesResolver,
    RelationShipsResolver,
    ProxyAccessGuard,
    ApplicationResolver,
    ApplicantResolver,
    ApplicantsResolver,
    CountryResolver,
    ProductsResolver
  ]
})
export class ProxyRoutingModule { }
