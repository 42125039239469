import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
// UI Kit
import { UIKitCommonModule } from '@uikit/components/common';
import { UkFormControlsModule } from '@uikit/components/uk-form-controls';

import { CreateUserFormComponent } from './components';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    // UI Kit
    UIKitCommonModule,
    UkFormControlsModule
  ],
  declarations: [
    CreateUserFormComponent
  ],
  exports: [
    CreateUserFormComponent
  ]
})
export class CommonFormsModule { }
