declare var environment: any;
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-create-user-form',
  templateUrl: './create-user-form.component.html',
  styleUrls: ['./create-user-form.component.scss']
})
export class CreateUserFormComponent{
  @Input() form: FormGroup;
  @Input() passwordMiddle: boolean;
  isWhiteLabel:boolean=environment.isWhiteLabel;

  
  
}


