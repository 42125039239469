import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UIKitCommonModule, UkFormControlsModule, UkDropdownModule } from '@uikit/components';
import { ProxyRoutingModule } from './routing/proxy-routing.module';
import * as components from './components';
import { LayoutModule } from 'app/layouts/layout.module';
import { CustomerService } from 'services/proxy';
import { ApplicantInformationFormsModule } from 'app/applicant-information-forms/applicant-information-forms.module';
import { SharedFormsModule } from 'app/shared-forms/shared-forms.module';
import { EnrollmentCommonModule } from 'app/enrollment-common/enrollment-common.module';
import { ProxyApplicationResolver } from './routing/resolvers/application.resolver';
import { ApplicationResolver } from 'app/core/resolvers';

@NgModule({
  declarations: [
    components.ProxySearchUserComponent,
    components.ProductSelectionComponent,
    components.ContactPageComponent,
    components.HandoffSuccessComponent
  ],
  imports: [
    CommonModule,
    ProxyRoutingModule,
    LayoutModule,
    ReactiveFormsModule,
    FormsModule,
    // UI Kit
    UIKitCommonModule,
    UkDropdownModule,
    UkFormControlsModule,
    ApplicantInformationFormsModule,
    SharedFormsModule,
    EnrollmentCommonModule
  ],
  providers: [
    CustomerService,
    TitleCasePipe,
    ProxyApplicationResolver,
    ApplicationResolver
  ],
  exports : [
    components.ContactPageComponent
  ]
})
export class ProxyModule { }
