declare var environment: any;

import { Injectable } from "@angular/core";
import { ISimpleProduct } from "models";
import { getProxyProducts } from "store/selectors";
import { Resolve } from "@angular/router";
import { Observable, of} from "rxjs";
import { Store } from "@ngrx/store";
import { EnrollmentState, GetProductsByOrganizationAction } from "store";
import { switchMap, first, map, tap, filter } from "rxjs/operators";

@Injectable()
export class ProductsResolver implements Resolve<Observable<ISimpleProduct[]>>{
    constructor(private store$: Store<EnrollmentState>){ }
    resolve(){
        return this.store$.select(getProxyProducts).pipe(
            tap(products => {
                if(products.length === 0){
                    this.store$.dispatch(new GetProductsByOrganizationAction(environment.applicationTypeName));
                }
            }),
            filter(products => !!products),
            first()
        )
    }
}